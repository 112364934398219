<template>
  <div  id="app ">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
 
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body{
  background-size: 123rem;
  background-position: top;
  background-image: url('../src/assets/fondo2.png');
  background-size: cover;
    background-position: center;
    background-repeat: no-repeat;height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

}
</style>
